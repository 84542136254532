<template>
  <h1>
    cargando...
  </h1>
</template>

<script>
export default {
    data(){
        return {
            subdominio: null,
            FS_LOCAL:"http://full-empresa.localhost/login-full-empresa-iaf",
            FS_SANDBOX:"https://fs-sandbox.fullsmart.work/login-full-empresa-iaf", // ya no existe
            FS_PRODUCCION:"https://iaf.fullsmartyes.com/login-full-empresa-iaf",
			PROVEEDORES: "https://proveedores.apps-peer.com",
			PROVEEDORES_PRUEBAS: "https://proveedores-prueba.iaf.solutions/login" // ya no existe
        }
    },
    mounted () {
        this.obtenerSubdominio()
        const user = 1
        let ruta = ''
		if (this.subdominio == 'proveedores-prueba') {
			ruta = `${this.PROVEEDORES_PRUEBAS}`
		}else if (this.subdominio == 'proveedores') {
			ruta = `${this.PROVEEDORES}`
		} else if (this.subdominio == 'localhost:8080') {
            ruta = `${this.FS_LOCAL}/${user}`
        }else if(this.subdominio == 'full-smart-pruebas'){
            ruta = `${this.FS_SANDBOX}/${user}`
        }else{
            ruta = `${this.FS_PRODUCCION}/${user}`
        }
        window.open(ruta,'_self')
        this.$router.push({name:'home'})
    },
    methods:{
        obtenerSubdominio(){
            let domain = window.location.host;
            let sub_dominio = domain.split('.').shift()
            return this.subdominio = sub_dominio
        },
    }
}
</script>

<style>

</style>